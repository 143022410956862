/*======================================
=          11. Countdown Css            =
=======================================*/

.countdown-deals{
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(4,1fr);
    
    @media #{$large-mobile}{
        grid-template-columns: repeat(4,1fr);
    }
    @media #{$small-mobile}{
        grid-template-columns: repeat(2,1fr);
    }
    
    &.counter-style {
        &--one {
            & .single-countdown {
                display: inline-block;
                & .single-countdown__time {
                    display: block;
                    font-size: 80px;
                    font-weight: 300;
                    line-height: 1;
                    color: $theme-color--default;
                    
                    margin-bottom: 10px;
                    @media #{$desktop-device}{
                        font-size: 60px;
                    }
                    @media #{$tablet-device,$large-mobile}{
                        font-size: 40px;
                    }
                }
                & .single-countdown__text {
                    display: block;
                    color: $theme-color-sub--heading;
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: 500;
                }
            }
        }
        &--two {
            & .single-countdown {
                display: inline-block;
                & .single-countdown__time {
                    display: block;
                    font-size: 80px;
                    font-weight: 300;
                    line-height: 1;
                    color: $white;
                    margin-bottom: 10px;
                    @media #{$desktop-device}{
                        font-size: 60px;
                    }
                    @media #{$tablet-device,$large-mobile}{
                        font-size: 40px;
                    }
                }
                & .single-countdown__text {
                    display: block;
                    color: $white;
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: 500;
                }
            }
        }
        
        &--four {
            & .single-countdown {
                display: inline-block;
                width: 70px;
                margin: auto;
                & .single-countdown__time {
                    display: block;
                    font-size: 30px;
                    font-weight: 400;
                    line-height: 1;
                    background: $white;
                    margin-bottom: 10px;
                    border-radius: 5px;
                    height: 90px;
                    line-height: 90px;
                    color: $black;
                }
                & .single-countdown__text {
                    display: block;
                    color: $white;
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: 500;
                }
            }
        }
    }
}
.time_circles {
    position: relative;
    width: 100%;
    height: 100%;
}
.time_circles > div {
    position: absolute;
    text-align: center;
    top: 0% !important;
    transform: translateY(50%);
}

.time_circles .textDiv_Days, .textDiv_Hours, .textDiv_Minutes, .textDiv_Seconds {
    color: #222222;
    font-size: 30px !important;
    @media #{$small-mobile}{
        font-size: 15px !important;
    }
    @media #{$large-mobile}{
        font-size: 20px !important;
    }
}
.time_circles > div > h4 {
    margin: 0px;
    padding: 0px;
    text-align: center;
    text-transform: capitalize;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
    font-size: 13px !important;
    color: #6d70a6;
    @media #{$small-mobile}{
        font-size: 11px !important;
    }
}

