/*=============================================
=              13. Box Icon Css              =
=============================================*/
.ht-box-icon {
    &.style-01 {
        & .icon-box-wrap {
            padding: 36px 10px 26px;
            border-radius: 5px;
            background: #fff;
            border: 2px solid #eee;
            transition: $transition--default;
            text-align: center;
            margin-top: 30px;
            & .icon {
                height: 150px;
                text-align: center;
                width: 150px;
                margin: auto;
                margin-bottom: 29px;
                color: $theme-color--default;
                svg {
                    & > *,
                    & path {
                      stroke: $theme-color--default; 
                    }
                }
            }
            
            & .content {
                text-align: center;
                & .heading {
                    font-weight: 500;
                    line-height: 1.25;
                    margin-bottom: 12px;
                }
                & .service_text{
                    color: $body-text-color;
                }
            }
        }
        &:hover {
            .icon-box-wrap {
                border-color: #fff;
                transform: translateY(-5px);
                background: #fff;
                box-shadow: 0 0 40px rgba(51,51,51,.1);
            }
        }
        & .feature-btn {
            & a {
                color: $theme-color--default;
                margin-top: 10px;
                &:hover {
                    color: $theme-color--two;
                }
            }
        }
    }
    &.style-02 {
        & .icon-box-wrap {
            padding: 30px 15px;
            border-radius: 5px;
            transition: $transition--default;
            text-align: center;
            display: flex;
            & .icon {
                min-width: 80px;
                margin-right: 10px;
                color: $theme-color--black;
                .svg-icon {
                    width: 65px;
                    height: 65px;
                }
                svg * {
                    stroke: currentColor;
                }
            }
            
            & .content {
                text-align: left;
                & .heading {
                    font-weight: 500;
                    line-height: 1.25;
                    margin-bottom: 12px;
                }
                & .service_text{
                    color: $body-text-color;
                }
            }
        }
        &:hover {
            .icon-box-wrap {
                border-color: #fff;
                transform: translateY(-5px);
                background: #fff;
                box-shadow: 0 0 40px rgba(51,51,51,.1);
            }
        }
        & .feature-btn {
            & a {
                color: $theme-color--default;
                margin-top: 20px;
                &:hover {
                    color: $theme-color--two;
                }
            }
        }
    }
    &.style-03 {
        & .icon-box-wrap {
            padding: 30px;
            border-radius: 5px;
            transition: all 0.3s ease-in-out;
            & .content-header {
                display: flex;
                align-items: center;
                & .icon {
                    flex-shrink: 0;
                    font-size: 48px;
                    font-weight: 48px;
                    min-width: 60px;
                    margin-right: 10px;
                    color: #d2a98e;
                }
                & .heading{
                    color: $theme-color--default;
                    margin-bottom: 10px;
                }
            }
            
            & .content {
                & .service_text{
                    color: $body-text-color;
                }
            }
        }
        &:hover {
            .icon-box-wrap {
                transform: translateY(-5px);
                background: #fff;
                box-shadow: 0 0 40px rgba(51,51,51,.1);
            }
        }
        @media #{$small-mobile}{
            & .icon-box-wrap {
                padding: 37px 10px;
                & .image {
                    height: 100px;
                    width: 100px;
                    line-height: 100px;
                }
            }
           
        }
    }
    &.style-04 {
        & .icon-box-wrap {
            padding: 19px 24px 30px;
            border-radius: 5px;
            background: #edf2fe;
            position: relative;
            z-index: 1;
            height: 100%;

            .feature-btn{
                margin-top: 20px;
            }

            & .icon {
                position: absolute;
                top: 30px;
                right: 30px;
                font-size: 48px;
                margin: 0;
                flex-shrink: 0;
                line-height: 1;
                color: #eddcd1;
                z-index: -1;
            }
            
            & .content {
                .sub-heading {
                    margin-bottom: 5px;
                }
                .heading {
                    font-weight: 700;
                    margin-bottom: 10px;
                }
                .text{
                    min-height: 80px;
                }
            }
            &.color-one {
                background-color: #faf6f3;
                & .icon {
                    color: #eddcd1;
                }
                & .content {
                    .sub-heading {
                        color: #803402;
                        margin-bottom: 20px;
                    }
                }
            }
            &.color-two {
                background-color: #edf2fe;
                & .icon {
                    color: #b9cefd;
                }
                & .content {
                    .sub-heading {
                        color: #4e84fa;
                    }
                }
            }
            &.color-three {
                background-color: #f0f5f4;
                & .icon {
                    color: #c7dbd7;
                }
                & .content {
                    .sub-heading {
                        color: #72a499;
                    }
                }
            }
            &.color-four {
                background-color: #fff5f6;
                & .icon {
                    color: #f5b4bd;
                }
                & .content {
                    .sub-heading {
                        color: #e7445b;
                    }
                }
            }
            &.color-five {
                background-color: #fcf7ee;
                & .icon {
                    color: #f5e1be;
                }
                & .content {
                    .sub-heading {
                        color: #e5b35d;
                    }
                }
            }
        }
    }
}
.feature-list-button-box {
    & .ht-btn {
        margin: 10px;
    }
}

.claim-grid-group {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    @media #{$desktop-device} {
        grid-template-columns: repeat( 3,1fr);
    }
    @media #{$tablet-device} {
        grid-template-columns: repeat( 2,1fr);
    }
    @media #{$large-mobile} {
        grid-template-columns: repeat( 2,1fr);
    }
    @media #{$extra-small-mobile} {
        grid-template-columns: repeat( 1,1fr);
    }
}
