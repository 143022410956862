/* ====================
    Toolbar Area 
========================*/
.aeroland__toolbar {
    & .inner {
        position: absolute;
        top: 200px;
        right: 100%;
        display: block;
        width: 40px;
        border: 1px solid #eee;
        border-right: 0;
        border-radius: 5px 0 0 5px;
        background: #fff;
        text-align: center;
        box-shadow: -3px 0 10px -2px rgba(0, 0, 0, 0.1);
        & a {
            display: block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            color: #222;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
    }
} 
.demo-option-wrapper {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 55px;
    & .demo-panel-header {
        padding: 40px 30px 30px;
        text-align: center;
    }

}
.demo-option-container {
    position: fixed;
    top: 0;
    left: 100%;
    background: #fff;
    height: 100%;
    width: 350px;
    max-width: calc(100% - 45px);
    z-index: 100000;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    &.open {
        -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);
        -webkit-box-shadow: -3px 0 50px -2px rgba(0, 0, 0, 0.14);
        box-shadow: -3px 0 50px -2px rgba(0, 0, 0, 0.14);
    } 
}
.demo-quick-option-list {
    padding: 27px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    & a {
        & img {
            border-radius: 5px;
        }
        &:hover {
            & img {
                transform: translateY(-3px);
                box-shadow: 0 10px 25px rgba(0,0,0,.2);
            }
        }
    }
}
