/*=================================
=         35. Infotechno Css        =
===================================*/
.infotechno-bg {
    background: rgb(244, 239, 233) none repeat scroll 0% 0%;
}
.infotechno-hero-text {
    & h6 {
        color: $theme-color--default;
        font-weight: 500;
        margin-bottom: 20px;
    }
    @media #{$large-mobile}{
        text-align: center;
        margin-top: 50px;
    }
}
.infotechno-hero-inner-images {
    margin-top: 20px;
    position: relative;
    @media #{$tablet-device}{
        margin-top: 160px;
    }
    @media #{$large-mobile}{
        max-width: 327px;
        margin: auto;
        .infotechno-inner-one {
            max-width: 260px;
            margin: auto;
        }
    }
}
.infotechno-inner-one {
    position: absolute;
    top: 10px;
}

.infotechno-inner-two {
    z-index: 1;
    position: relative;
}

.infotechno-hero-text {
    max-width: 605px;
    float: right;
    width: 100%;
    padding-left: 30px;
    @media #{$large-mobile}{
        padding-left: 0;
        float: inherit;
        margin: 50px auto 20px;
    }
}


/*-------------------------
    video section css
--------------------------*/
.infotechno-section-bg-01 {
    background: url(../images/patterns/mitech-home-infotechno-section-company-bg.webp) top 140px left no-repeat,-webkit-linear-gradient(top,#FFF 0,#F5F5F5 100%);
}
.infotechno-video-bg {
    background-image: url(../images/bg/home-infotechno-video-intro-bg.webp);
    background-size: cover;
    background-repeat: no-repeat;
}
.infotechno-video-ptb {
    padding: 150px 0;
    @media #{$tablet-device ,$large-mobile}{
        padding-top: 100px;
        padding-bottom: 100px;
    }
}
.vieeo-content-inner {
    & .heading {
        line-height: 1.34;
        font-weight: 600;
        color: #fff;
        margin-bottom: 65px;
    }
}
.viewo-text-wrap {
    & .single-popup-wrap.video-link {
        display: inline-block;
        & .ht-popup-video.video-button {
            position: relative;
            margin-right: 0;
            display: inline-block;
            height: 78px;
            width: 78px;
        }
    }
    & .video-content {
        display: flex;
        align-items: center;
    }
    & .video-text {
        margin-top: 0;
        margin-left: 59px;
        font-size: 18px;
        font-weight: 500;
        color: rgba(255,255,255,.8);
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 1.45;
    }
}
.infotechno-blog-list {
    & li {
        position: relative;
        &:first-child {
            & a {
                margin-top: -16px;
            }
        }
        & a {
            position: relative;
            font-size: 15px;
            font-weight: 600;
            padding: 0 0 0 30px;
            margin-top: 20px;

            &::before,
            &::after {
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                font-family: 'Font Awesome 5 Pro';
                font-weight: 900;
                transition: $transition--default;
            }
            &::after,
            &::before {
                content: '\f30b';
                position: absolute;
                top: 7px;
                left: 0;
            }
            &::before {
                opacity: 0;
                visibility: hidden;
                -webkit-transform: translateX(-100%);
                -ms-transform: translateX(-100%);
                transform: translateX(-100%);    
            }
            &:hover {
                &::before {
                    opacity: 1;
                    visibility: visible;
                    -webkit-transform: translateX(0);
                    -ms-transform: translateX(0);
                    transform: translateX(0);
                }
                &::after {
                    opacity: 0;
                    visibility: hidden;
                    -webkit-transform: translateX(100%);
                    -ms-transform: translateX(100%);
                    transform: translateX(100%);
                }
            }
        }
    }
}