
/*===========================================
=        03. Custom Animation Css
===========================================*/

@-webkit-keyframes moveVertical {
    to {
        opacity: 1;
        -webkit-transform: translateY(0)
    }
}

@keyframes moveVertical {
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}


@-webkit-keyframes moveHorizontal {
    to {
        -webkit-transform: translateX(0);
        opacity: 1
    }
}

@keyframes moveHorizontal {
    to {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }
}

.move-up {
    -webkit-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
    &.animate{
        -webkit-transform: translateY(100px);
        -ms-transform: translateY(100px);
        transform: translateY(100px);
        -webkit-animation: moveVertical .65s ease forwards;
        animation: moveVertical .65s ease forwards;
    }
}


.move-up-x {
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
    &.animate{
        -webkit-transform: translateX(100px);
        -ms-transform: translateX(100px);
        transform: translateX(100px);
        -webkit-animation: moveHorizontal .65s ease forwards;
        animation: moveHorizontal .65s ease forwards;
    }
}


/* ------------------------
    Custom Animation 01 
----------------------------*/

@-webkit-keyframes headerSlideDown {
    0% {
        margin-top: -100px
    }

    to {
        margin-top: 0
    }
}

@keyframes headerSlideDown {
    0% {
        margin-top: -100px
    }

    to {
        margin-top: 0
    }
}


/* --------------------------------------
    Custom Hide Animation
---------------------------------------*/

@-webkit-keyframes hide-animation {
    0% {
        -webkit-transform: translateY(0);
        opacity: 1
    }
    to {
        -webkit-transform: translateY(60px);
        opacity: 0
    }
}

@keyframes hide-animation {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }
    to {
        -webkit-transform: translateY(60px);
        transform: translateY(60px);
        opacity: 0
    }
}



/* --------------------------------------
    Custom Slide Show Animation
---------------------------------------*/

@-webkit-keyframes show-animation {
    0% {
        -webkit-transform: translateY(60px);
        opacity: 0
    }
    to {
        -webkit-transform: translateY(0);
        opacity: 1
    }
}

@keyframes show-animation {
    0% {
        -webkit-transform: translateY(60px);
        transform: translateY(60px);
        opacity: 0
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }
}


/* --------------------------------------
    Custom Slide Out To Right
---------------------------------------*/

@-webkit-keyframes slide-out-to-right {
    0% {
        -webkit-transform: translateX(0);
        opacity: 1
    }
    to {
        -webkit-transform: translateX(40px);
        opacity: 0
    }
}

@keyframes slide-out-to-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }
    to {
        -webkit-transform: translateX(60px);
        transform: translateX(60px);
        opacity: 0
    }
}



/* --------------------------------------
    Custom Slide Out To Left
---------------------------------------*/

@-webkit-keyframes slide-in-to-left {
    0% {
        -webkit-transform: translateX(60px);
        opacity: 0
    }
    to {
        -webkit-transform: translateX(0);
        opacity: 1
    }
}

@keyframes slide-in-to-left {
    0% {
        -webkit-transform: translateX(40px);
        transform: translateX(40px);
        opacity: 0
    }
    to {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }
}




/* ------------------------
    Brook Hover Effect 
---------------------------*/

.brook-transition{
    -webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
    transition: all .3s cubic-bezier(.645,.045,.355,1);
}
.bk-hover{
    a{
        position: relative;
        overflow: hidden;
        z-index: 1;
        display: inline-block;
        color: #999;
        &::after{
            content: "";
            width: 0;
            height: 1px;
            bottom: 0;
            position: absolute;
            left: auto;
            right: 0;
            z-index: -1;
            -webkit-transition: width .6s cubic-bezier(.25,.8,.25,1) 0s;
            transition: width .6s cubic-bezier(.25,.8,.25,1) 0s;
            background: currentColor;
        }
        &:hover{
             &::after{
                width: 100%;
                left: 0;
                right: auto;
             }
        }
    }
    &.hover-text-dark{
        a{
            &:hover{
                
            }
        }
    }
}


/* ------------------------
    Separator Bounce 
---------------------------*/

@-webkit-keyframes separator-bounce {
    0%,
    80%,
    to {
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes separator-bounce {
    0%,
    80%,
    to {
        -webkit-transform: scale(.5);
        transform: scale(.5)
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}
